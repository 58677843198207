import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
// import Map from './views/Map'
import testmain from "@/views/Testmain";
import router from './router'


// const app=createApp(Map)
const app=createApp(testmain)
app.use(ElementPlus).use(router).mount('#app')
// axios.defaults.timeout=5000
// app.config.globalProperties.$http=axios