<template>
  <div class="login">
    <el-form class="el-form login-form">
      <h3 class="title">仁欣生态环境服务平台</h3>
      <el-form ref="formRef" :model="form" label-width="80px">
        <el-form-item label="账号：">
          <el-input v-model="form.username" :prefix-icon="Avatar"></el-input>
        </el-form-item>
        <el-form-item label="密码：">
          <el-input v-model="form.password" type="password" :prefix-icon="Lock"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" class="el-button">登陆</el-button>
        </el-form-item>
      </el-form>
    </el-form>
      <div class="el-footer">
        <p>Copyrights 2022©浙江仁欣环科院有限责任公司</p>
        <a href='https://beian.miit.gov.cn/'>备案号：浙ICP备18055521号-3</a></div>
  </div>

</template>

<script setup>
import { reactive } from 'vue'
import {Avatar,Lock} from '@element-plus/icons-vue'

// do not use same name with ref
const form = reactive({
  username: '',
  password:''
})

const onSubmit = () => {
  alert("账号密码错误！")
}
</script>

<style>
html{
  height: 100%;
}
body {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
}
.title{
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Arial,sans-serif;
  box-sizing: inherit;
  margin: 0 auto 30px auto;
  text-align: center;
  color: #707070;
}
.login{
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Arial,sans-serif;
  box-sizing: inherit;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-image: url(../assets/login.jpg);
  background-size:cover;
  height: 100%;
}
.login-form{
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Arial,sans-serif;
  box-sizing: inherit;
  border-radius: 6px;
  background: #fff;
  width: 400px;
  padding: 25px 25px 5px 25px;
}
.el-footer{
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  box-sizing: inherit;
  height: 40px;
  line-height: 10px;
  position: fixed;
  bottom: 20px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.el-button{
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #dcdfe6;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: .1s;
  font-weight: 400;
  -webkit-user-select: none;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
  background: #46a6ff;
  border-color: #46a6ff;
  color: #fff;
  width: 40%;
}
</style>